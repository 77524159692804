var globalMap = {

	element: $('[data-global-map]'),

	links: false,

	pins: false,

	description: false,

	pinList: false,

	areas: $('.m-global-map__area'),

	linksTimeout: false,

	linksTimeoutMouseEnter: false,

	linksAllowHover: true,

	popupOpen: false,

	clear: function () {

		if (globalMap.element.length) {

			globalMap.popupOpen = false;
			globalMap.areas.removeClass('m-global-map__area--active');
			globalMap.links.removeClass('m-global-map__pin__link--hover');

		}

	},

	init: function () {

		if (globalMap.element.length) {

			// duplicate and move description for mobile

			globalMap.description = globalMap.element.find('[data-global-map-description]');
			var descriptionClone = globalMap.description.clone().addClass('m-global-map__description--mobile t-global-map-description--mobile');
			globalMap.pinList = globalMap.element.find('[data-global-map-pin-list]');
			globalMap.pinList.before(descriptionClone);


			globalMap.pins = globalMap.element.find('[data-global-map-pin]');
			globalMap.links = globalMap.element.find('[data-global-map-pin-link]');

			globalMap.links.each(function () {

				var target = $(this),
				text = target.find('[data-global-map-pin-text]').html();

				target.append(
					'<div class="js-tool-tip" data-tool-tip aria-hidden="true">' +
						'<div class="js-tool-tip__inner">' +
							'<div class="js-tool-tip__inner__inner"><span class="m-arrow-link">' + text + '</span></div>' +
						'</div>' +
					'</div>'
				);

			});

			if (!site.isTouch()) {

				globalMap.links.hover(function () {

					var target = $(this);

					if (window.matchMedia('(min-width: ' + site.bp.tablet + 'px)').matches) {

						if (globalMap.linksAllowHover) {

							clearTimeout(globalMap.linksTimeout);
							clearTimeout(globalMap.linksTimeoutMouseEnter);

							var tooltip = target.find('[data-tool-tip]'),
							svg_element = $('.m-global-map__area--' + target.attr('data-global-map-pin-link'));

							target.addClass('m-global-map__pin__link--hover');
							svg_element.addClass('m-global-map__area--active');

							tooltip.addClass('js-tool-tip--visible');
							globalMap.linksTimeout = setTimeout(function () { tooltip.addClass('js-tool-tip--in'); }, 1);

						} else {

							clearTimeout(globalMap.linksTimeoutMouseEnter);

							globalMap.linksTimeoutMouseEnter = setTimeout(function () {
								target.trigger('mouseenter');
							}, 250);

						}

					}

				}, function () {

					if (window.matchMedia('(min-width: ' + site.bp.tablet + 'px)').matches) {

						globalMap.linksAllowHover = false;

						clearTimeout(globalMap.linksTimeout);
						clearTimeout(globalMap.linksTimeoutMouseEnter);

						var target = $(this),
						tooltip = target.find('[data-tool-tip]'),
						svg_element = $('.m-global-map__area--' + target.attr('data-global-map-pin-link'));

						if (!globalMap.popupOpen) {

							target.removeClass('m-global-map__pin__link--hover');
							svg_element.removeClass('m-global-map__area--active');

						}

						tooltip.removeClass('js-tool-tip--in');

						globalMap.linksTimeout = setTimeout(function () {

							tooltip.removeClass('js-tool-tip--visible');
							globalMap.linksAllowHover = true;

						}, 250);

					}

				});

			}

			globalMap.links.off('click').on('click', function (e) {

				var target = $(this),
				tooltip = target.closest('[data-global-map-pin]').find('[data-tool-tip]'),
				svg_element = $('.m-global-map__area--' + target.attr('data-global-map-pin-link')),
				time_until_open = 0;

				globalMap.popupOpen = true;

				if (site.isTouch()) {

					time_until_open = 500;

					target.addClass('m-global-map__pin__link--hover');

				}

				svg_element.addClass('m-global-map__area--active');

				setTimeout(function () {

					$.magnificPopup.open({
						items: {
							src: target.attr('href'),
							type: 'inline',
						},
						focus: '.m-global-map__info__item__title',
						mainClass: 'mfp-zoom-in mfp-no-close-text'
					});

				}, time_until_open);

				e.preventDefault();
				return false;

			});

		}

	}

};

$(function () {
	globalMap.init();
});